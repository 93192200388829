import { translate } from '@/core/localization/translate';
export const {
  title,
  subtitle1,
  text1,
  subtitle2,
  text2,
  subtitle3,
  text3
} = translate({
  "title": "The biggest marketplace in Europe for DIY, Home & Garden",
  "subtitle1": "Millions of products",
  "text1": "Loads more than in a shop, and without needing to navigate all the aisles",
  "subtitle2": "Delivery to your home or click & collect",
  "text2": "Whichever suits you best",
  "subtitle3": "Hundreds of dedicated experts online",
  "text3": "Like Macgyver but online"
});